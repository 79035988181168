<template>
  <div>
    <h5 class="text-center" v-if="!hideHeader">
      {{ $t('terms.terms_and_conditions') }}
    </h5>
    <p class="text-center">{{ $t('terms.a_general_terms_conditions_of_use') }}</p>
    <p class="text-right">{{ $t('terms.last_updated') }}</p>
    <p>
      {{ $t('terms.first_par_terms') }}
    </p>
    <p>
      {{ $t('terms.second_par_terms') }}
    </p>
    <h6>{{ $t('terms.first_conditions') }}</h6>
    <p>
      {{ $t('terms.first_one_conditions') }}
    </p>
    <p>
      {{ $t('terms.first_two_conditions') }}
    </p>
    <p>
      {{ $t('terms.first_three_conditions') }}
    </p>

    <h6>{{ $t('terms.second_conditions') }}</h6>
    <p>
      {{ $t('terms.second_one_conditions') }}
    </p>
    <p>
      {{ $t('terms.second_two_conditions') }}
    </p>
    <h6>{{ $t('terms.third_conditions.main') }}</h6>
    <p>
      {{ $t('terms.third_conditions.one') }}
    </p>
    <p>
      {{ $t('terms.third_conditions.two') }}
    </p>
    <p>
      {{ $t('terms.third_conditions.third') }}
    </p>

    <h6>{{ $t('terms.four_conditions') }}</h6>
    <!-- 4.1 -->
    <p>
      {{ $t('terms.four_one_conditions.main') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.one') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.two') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.third') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.fourth') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.fifth') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.six') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.seven') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_one_conditions.eight') }}
    </p>
<!-- 4.2 -->
    <p>
      {{ $t('terms.four_two_conditions.main') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.one') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.two') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.third') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.four') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.fifth') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.six') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.seven') }}
    </p>
    <p class="indent-2x">
      {{ $t('terms.four_two_conditions.eight') }}
    </p>
    <p>
      {{$t('terms.four_third_conditions')}}
    </p>
    <p>
      {{$t('terms.four_four_conditions')}}
    </p>
    <p>{{$t('terms.four_fifth_conditions.main')}}</p>
    <p class="indent-2x">
      {{$t('terms.four_fifth_conditions.one')}}
    </p>
    <p class="indent-2x">
      {{$t('terms.four_fifth_conditions.two')}}
    </p>
    <p class="indent-2x">
      {{$t('terms.four_fifth_conditions.third')}}
    </p>
    <h6>{{$t('terms.fifth_conditions.main')}}</h6>
    <p>
      {{$t('terms.fifth_conditions.one')}}
    </p>
    <p>
      {{$t('terms.fifth_conditions.two')}}
    </p>
    <p>
      {{$t('terms.fifth_conditions.third')}}
    </p>
    <p>{{$t('terms.fifth_conditions.four')}}</p>
    <h6>{{$t('terms.six_conditions.main')}}</h6>
    <p>
      {{$t('terms.six_conditions.one')}}
    </p>
    <p>
      {{$t('terms.six_conditions.two')}}
    </p>
    <p>
      {{$t('terms.six_conditions.third')}}
    </p>
    <p>
      {{$t('terms.six_conditions.four')}}
    </p>
    <p>
      {{$t('terms.six_conditions.fifth')}}
    </p>
    <p class="indent-2x">
      {{$t('terms.six_conditions.sub_fifth.one')}}
      </p>
    <p class="indent-2x">
      {{$t('terms.six_conditions.sub_fifth.two')}}
    </p>
    <p class="indent-2x">
      {{$t('terms.six_conditions.sub_fifth.third')}}
    </p>
    <p>
      {{$t('terms.six_conditions.six')}}
    </p>
    <p>
      {{$t('terms.six_conditions.seven')}}
    </p>
    <p>
      {{$t('terms.six_conditions.eight')}}
    </p>
    <p>
      {{$t('terms.six_conditions.nine')}}
    </p>
    <p>
      {{$t('terms.six_conditions.ten')}}
    </p>
    <h6>{{$t('terms.seven_conditions.main')}}</h6>
    <p>
      {{$t('terms.seven_conditions.desc')}}
    </p>
    <h6>{{$t('terms.eight_conditions.main')}}</h6>
    <p>
      {{$t('terms.eight_conditions.desc')}}
    </p>
    <h6>{{$t('terms.nine_conditions.main')}}</h6>
    <p>
      {{$t('terms.nine_conditions.desc')}}
    </p>
    <h6>{{$t('terms.ten_conditions.main')}}</h6>
    <p>
      {{$t('terms.ten_conditions.one')}}
    </p>
    <p>
      {{$t('terms.ten_conditions.two')}}
    </p>
    <p>
      {{$t('terms.ten_conditions.third')}}
    </p>
    <p>
      {{$t('terms.ten_conditions.four')}}
    </p>
    <p>
      {{$t('terms.ten_conditions.fifth')}}
    </p>
    <p>
      {{$t('terms.ten_conditions.six')}}
    </p>
    <p> 
      {{$t('terms.ten_conditions.desc')}}
    </p>
    <p class="text-center">{{$t('terms.b_booking_and_reservation_terms_and_conditions')}}</p>
    <p>
      {{$t('terms.b_desc1')}}
    </p>
    <p>
      {{$t('terms.b_desc2')}}
    </p>
    <h6>{{$t('terms.b_first.main')}}</h6>
    <p>
      {{$t('terms.b_first.one')}}
    </p>
    <p>
      {{$t('terms.b_first.two')}}
    </p>
    <h6>{{$t('terms.b_two.main')}}</h6>
    <p>
      {{$t('terms.b_two.desc')}}
    </p>
    <h6>{{$t('terms.b_third.main')}}</h6>
    <p>
      {{$t('terms.b_third.one')}}
    </p>
    <p>
      {{$t('terms.b_third.two')}}
    </p>
    <h6>{{$t('terms.b_four.main')}}</h6>
    <p>
      {{$t('terms.b_four.one')}}
    </p>
    <p>
      {{$t('terms.b_four.two')}}
    </p>
    <p>
      {{$t('terms.b_four.third')}}
    </p>
    <h6>{{$t('terms.b_fifth.main')}}</h6>
    <p>
      {{$t('terms.b_fifth.one')}}
    </p>
    <p>
      {{$t('terms.b_fifth.two')}}
    </p>
    <p>
      {{$t('terms.b_fifth.third')}}
    </p>
    <p>
      {{$t('terms.b_fifth.four')}}
    </p>
    <h6>{{$t('terms.b_six.main')}}</h6>
    <p>
      {{$t('terms.b_six.one')}} <br/> 
      {{$t('terms.b_six.one_desc')}}</p>

    <p>
      {{$t('terms.b_six.two')}} <br/> 
      {{$t('terms.b_six.two_desc')}}
    </p>
    <h6>{{$t('terms.b_seven.main')}}</h6>
    <p>
      {{$t('terms.b_seven.one')}}
    </p>
    <p>
      {{$t('terms.b_seven.two')}}
    </p>
    <p>
      {{$t('terms.b_seven.third')}}
    </p>
    <p>{{$t('terms.b_seven.four')}}</p>
    <h6>{{$t('terms.b_eight.main')}}</h6>
    <p>{{$t('terms.b_eight.desc')}}</p>
  </div>
</template>

<script>
export default {
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  text-indent: 3em;
  font-weight: 400 !important;
  line-height: 1.65em;
}
.indent-2x {
  text-indent: 5em;
}
h6 {
  font-weight: 600;
}
</style>
