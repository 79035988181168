import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { isAuthenticated } from './utils'
import { isUATEnv } from './helpers/network'
import logo from '@/assets/logo.jpeg'
import { i18n } from './i18n'
import BlankTemplate from './views/Main.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

const releasePages = [
  'Home',
  'AboutUs',
  'ContactUs',
  'UnderConStruction',
  'Recruitment',
  'Terms',
  'PrivacyAndPolicy',
]

const blockPages = ['Faq', 'BookingOneTime', 'BookingMultiPackage', 'Pricing']

// Just checking access to
const requiresAuth = async (to, from, next) => {
  console.info('CHECK REQUIRE AUTH')
  const isAuth = isAuthenticated()

  if (!isAuth || isAuth === null) {
    localStorage.isRefreshTokenProcess = true
    const isSuccess = await store.dispatch('auth/refreshToken').then(async () => {
      console.info('CHECK REQUIRE AUTH', isSuccess)

      if (isSuccess) {
        console.log('Navigate to', to.name)
        next()
      } else {
        // alert("reset state")
        for (let i = 0; i < 99; i++) {
          console.log(
            'isRefresh=' +
              localStorage.isRefreshTokenProcess +
              '-' +
              (localStorage.isRefreshTokenProcess == 'false')
          )
          if (localStorage.isRefreshTokenProcess === 'false') {
            // next();
            window.location.reload()
          }
        }
        console.log('localStorage.access_token', localStorage.access_token)
        if (!localStorage.access_token) {
          store.commit('auth/RESET_STATE')
          localStorage.setItem('redirectLogin', to.fullPath)
          const toRouteName = to.name
          next({ path: '/' , query: { toRouteName : toRouteName }})
        }
      }
    })
  } else {
    console.log('Navigate to', to.name)
    next()
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/Home.vue'),
      meta: store.getters['ogMeta/GET_METATAGS'],
    },
    {
      path: '/:locale',
      component: BlankTemplate,
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/Home.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'home/:path',
          name: 'Home_2',
          component: () => import('@/views/Home.vue'),
        },

        {
          path: 'timeout',
          name: 'TimeOut',
          component: () => import('@/views/TimeOut_2.vue'),
        },
        {
          path: 'slip-confirm',
          name: 'Slip Confirm',
          component: () => import('@/views/payment/SlipConfirm.vue'),
        },
        {
          path: 'lineLogin',
          name: 'LineLogIn',
          component: () => import('@/views/LineLogin.vue'),
        },
        {
          path: 'maids',
          name: 'Maids',
          component: () => import('@/views/Maids.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
          children: [
            {
              path: 'id',
              name: 'MaidUser',
              component: () => import('@/views/Maids.vue'),
            },
          ],
        },
        {
          path: 'pricing',
          name: 'Pricing',
          component: () => import('@/views/Pricing.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'faq',
          name: 'Faq',
          component: () => import('@/views/faq/Faq.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
          // beforeEnter: requiresAuth
        },
        {
          path: 'about-us',
          name: 'AboutUs',
          component: () => import('@/views/AboutUs.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'contact-us',
          name: 'ContactUs',
          component: () => import('@/views/ContactUs.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'book-one-time',
          name: 'BookingOneTime',
          component: () => import('@/views/bookings/BookOneTime.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
          // beforeEnter: requiresAuth
        },
        {
          path: 'book-multi-package',
          name: 'BookingMultiPackage',
          component: () => import('@/views/bookings/BookMultiPackage.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
          // beforeEnter: requiresAuth
        },
        {
          path: 'select-maid',
          name: 'SelectMaid',
          component: () => import('@/views/bookings/SelectMaid.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'my-account',
          name: 'MyAccount',
          component: () => import('@/views/MyAccount.vue'),
          beforeEnter: requiresAuth,
        },
        {
          path: 'reset-password',
          name: 'ResetPassword',
          component: () => import('@/views/ResetPassword'),
          // beforeEnter: requiresAuth,
        },
        {
          path: 'payment',
          name: 'PaymentMedthod',
          component: () => import('@/views/payment/PaymentMedthod.vue'),
        },
        {
          path: 'payment/result',
          name: 'PaymentResult',
          component: () => import('@/views/payment/PaymentResult.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'payment/history',
          name: 'History',
          component: () => import('@/views/payment/History.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'booking-review',
          name: 'BookingReview',
          component: () => import('@/views/payment/BookingReview.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'verify-phone',
          name: 'VerifyPhone',
          component: () => import('@/views/payment/VerifyPhone.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'booking-confirm',
          name: 'BookingConfirm',
          component: () => import('@/views/payment/BookingConfirm.vue'),
          // beforeEnter: requiresAuth
        },
        // {
        //   path: "/chats",
        //   name: "MyChat",
        //   component: () => import("@/views/chat/MyChat.vue"),
        //   // beforeEnter: requiresAuth
        // },
        // {
        //   path: "/chats/message/:userId/:channelUrl",
        //   name: "ChatMessage",
        //   component: () => import("@/views/chat/ChatMessage.vue"),
        //   // beforeEnter: requiresAuth
        // },
        {
          path: 'my-booking',
          name: 'MyBooking',
          component: () => import('@/views/MyBooking.vue'),
          beforeEnter: requiresAuth,
        },
        {
          path: 'my-favorite-maid',
          name: 'MyFavoriteMaid',
          component: () => import('@/views/MyFavoriteMaid.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'my-booking/one-time/:id',
          name: 'MyBookingOneTime', // Detail
          component: () => import('@/views/bookings/BookOneTime.vue'),
          beforeEnter: requiresAuth
        },
        {
          path: 'my-booking/multi-package/:id',
          name: 'MyBookingMultiPackage', // Detail
          component: () => import('@/views/bookings/BookMultiPackage.vue'),
          beforeEnter: requiresAuth
        },
        {
          path: 'my-multi-package',
          name: 'MyMultiPackage',
          component: () => import('@/views/MyMultiPackage.vue'),
          beforeEnter: requiresAuth
        },
        {
          path: 'welcome',
          name: 'Welcome',
          component: () => import('@/views/Welcome.vue'),
          // beforeEnter: requiresAuth
        },
        {
          path: 'under-construction',
          name: 'UnderConStruction',
          component: () => import('@/views/UnderConstruction.vue'),
        },
        {
          path: 'recruitment',
          name: 'Recruitment',
          component: () => import('@/views/RecruitMaid.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'terms',
          name: 'Terms',
          component: () => import('@/views/TermsAndConditions.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyAndPolicy',
          component: () => import('@/views/PrivacyAndPolicy.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'user-data-deletion-instructions',
          name: 'UserDataDeletionInstructions',
          component: () => import('@/views/userDeletion.vue'),
          meta: store.getters['ogMeta/GET_METATAGS'],
        },
        {
          path: 'admin',
          name: 'Admin',
          component: () => import('@/views/AdminRedirect.vue'),
        },
        {
          path: ':catchAll(.*)',
          redirect: {
            name: 'Home',
          },
        }
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((res) => {
      setTimeout(() => {
        res({ x: 0, y: 0 })
      }, 250)
    })
    // return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // if (to.params.locale != 'en' && to.params.locale != 'th') {
  //   next({ path: `/${i18n.locale}` })
  // } else {
  //   store.dispatch('ogMeta/SET_META', to.name)
  // }
  to.params.locale = i18n.locale
  store.dispatch('ogMeta/SET_META', to)
  const isLoggedIn = store.state.auth.isLoggedIn
  console.log('router to', to)

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  // if (!nearestWithMeta) return next()

  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement('meta')
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key])
        })
        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')
        return tag
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag))
  }
  // Turn the meta tag definitions into actual elements in the head.
  // let resetPage = ['/book-one-time', '/book-multi-package', '/booking-review']
  let resetPage = ['BookingOneTime', 'BookingMultiPackage', 'BookingReview']
  if (!resetPage.includes(to.name)) {
    localStorage.isFromBookingReview = null
  }
  // console.log(i18n.locale)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({ path: '/' })
    } else {
      next()
    }
    // else {
    //   if (to.params.locale !== 'th' && to.params.locale !== 'en') {
    //     next({ path: `/${i18n.locale}${to.fullPath}` })
    //   } else {
    //     next()
    //   }
    // }
  } else {
    const toRouteName = to.name
    // alert(toRouteName)
    // if (to.params.locale !== 'th' && to.params.locale !== 'en') {
    //   next({ path: `/${i18n.locale}${to.fullPath}` })
    // } else {
    // next()
    // }
    
    //add Trailing Slashes when it's not query
    if (to.fullPath.slice(-1) !== '/' && to.fullPath.indexOf('?') === -1) {
      next({ path: to.fullPath + '/' })
    }else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  store.commit('setRouter', { to, from })
  document.title = to.meta.title || 'Bluuu'
  let params = window.location.search.substr(1)
  if (to.name === '')
    switch (from.name) {
      case 'MyBookingMultiPackage':
        if (
          to.name !== 'SelectMaid' &&
          to.name !== 'BookingReview' &&
          to.name !== 'BookingOneTime'
        ) {
          store.commit('booking/resetState')
        }
        break
      case 'MyBookingOneTime':
        if (
          to.name !== 'SelectMaid' &&
          to.name !== 'BookingReview' &&
          to.name !== 'BookingMultiPackage'
        ) {
          store.commit('booking/resetState')
        }
        break
      case 'BookingOneTime':
        if (to.name !== 'SelectMaid' && to.name !== 'BookingReview') {
          // store.commit("booking/resetState");
        }
        break
      case 'BookingMultiPackage':
        if (to.name !== 'SelectMaid' && to.name !== 'BookingReview') {
          // store.commit("booking/resetState");
        }
        break

      default:
        break
    }
})

export default router
